import styles from './SimpleCard.module.sass'

import React from 'react'
import Markdown from '../../Markdown/Markdown'
import Media from '../../Media/Media'

const SimpleCard = (props) => {
	const { icon, title, summary, modifiers } = props
	return (
		<div className={ `${ styles['root'] } ${ (modifiers || []).map(m => styles[m] || '').join(' ') }` }>
			<div className={ `thumb ${ styles['thumb'] }` }>
				<Media src={ icon }/>
			</div>
			<div>
				<h4 className={ styles['title'] }>{ title }</h4>
				<div className={ styles['summary'] }>
					<Markdown>{ summary }</Markdown>
				</div>
			</div>
		</div>
	)
}
export default SimpleCard