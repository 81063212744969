import styles from './Media.module.sass'
import React, { useState, useEffect } from 'react'
import 'intersection-observer'
const Media = (props) => {
	const { src: propsSrc, srcSet = {}, modifiers = [] } = props
	const [currentMedia, setCurrentMedia] = useState()
	const src = propsSrc || srcSet[currentMedia]

	useEffect(() => {
		const handleResize = () => {
			const w = window.innerWidth
			if (w <= 800 && srcSet.mobile) {
				// setSourceImages(imagesSet.mobile)
				setCurrentMedia('mobile')
			} else {
				// setSourceImages(imagesSet.all)
				setCurrentMedia('all')
			}
		}
		handleResize()
		window.addEventListener('resize', handleResize)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
		// if (imagesSet && typeof window !== 'undefined') {

		// } else if (propsImages) {
		// 	setSourceImages(propsImages)
		// }
	}, [src, srcSet])

	return (
		<picture
			className={ `${ styles['picture'] } ${ (modifiers || []).map(m => styles[m] || '').join(' ') }` }
			style={ { backgroundImage: src ? `url("${ src }")` : '' } }
		/>
	)
}

export default Media
