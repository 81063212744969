import styles from './App.module.sass'

import React, { useState, useRef, useEffect } from 'react'
import { Switch, Route, useLocation, useHistory } from 'react-router-dom'
import { Helmet } from "react-helmet"

import LMenu from './components/LMenu/LMenu'
import logo from './assets/logo.svg'
import Homepage from './controllers/Homepage'
import UiUxDevelopment from './controllers/UiUxDevelopment'
import ApisProgramming from './controllers/ApisProgramming'
import About from './controllers/About'
import GlobalProvider from './controllers/GlobalContext'
import GetAQuote from './forms/GetAQuote/GetAQuote'
import Footer from './components/Footer/Footer'
import Layout from './components/Layout/Layout'
import CallToAction from './components/CallToAction/CallToAction'
import NoPage from './controllers/NoPage'

const navigation = [
	{
		name: 'Home',
		to: '/',
		exact: true,
		title: 'Jimmy Boys'
	},
	{
		name: 'UI/UX Development',
		to: '/ui-ux-development',
		title: 'UI/UX Development'
	},
	{
		name: 'APIs Programming',
		to: '/apis-programming',
		title: 'APIs Programming'
	},
	{
		name: 'About us',
		to: '/about-us',
		title: 'About Us'
	}
]

const externalLinks = [
	{
		name: 'Rust',
		to: 'https://www.rust-lang.org/'
	},
	{
		name: 'WebAssembly',
		to: 'https://webassembly.org/'
	},
	{
		name: 'React',
		to: 'https://reactjs.org/'
	},
	{
		name: 'Electron',
		to: 'https://electronjs.org/'
	},
	{
		name: 'SwiftUI',
		to: 'https://developer.apple.com/xcode/swiftui/'
	},
	{
		name: 'Flutter',
		to: 'https://flutter.dev/'
	},
	{
		name: 'GraphQL',
		to: 'https://graphql.org/'
	},
	{
		name: 'DGraph',
		to: 'https://dgraph.io/'
	}
]
const socialLinks = [
	{
		name: 'Facebook',
		to: 'https://www.facebook.com/jimmyboysofficial/',
		icon: 'facebook'
	},
	{
		name: 'Instagram',
		to: 'https://www.instagram.com/jimmyboysofficial/',
		icon: 'instagram'
	},
	{
		name: 'LinkedIn',
		to: 'https://www.linkedin.com/company/jimmy-boys/',
		icon: 'linkedin'
	}
	// ,
	// {
	// 	name: 'Twitter',
	// 	to: 'https://electronjs.org/',
	// 	icon: 'twitter'
	// }
]



function App() {
	const backdrop = useRef(null)
	const [showGetAQuote, setShowGetAQuote] = useState(false)
	const location = useLocation()
	const { action } = useHistory()

	const openGetAQuote = () => {
		setShowGetAQuote(true)
	}
	const closeGetAQuote = () => {
		setShowGetAQuote(false)
	}

	useEffect(() => {
		if (action !== 'POP' && !(location.hash || '').length) {
			window.scrollTo(0, 0)
		}
	}, [location, action])

	useEffect(() => {
		const targetElement = backdrop.current
		if (!targetElement || !showGetAQuote) {
			document.body.classList.remove(styles['no-scroll'])
			return
		}
		document.body.classList.add(styles['no-scroll'])
		targetElement.ontouchstart = (e) => {
			e.preventDefault();
		}
		return () => {
			targetElement.ontouchend = null
		};
	}, [backdrop, showGetAQuote])

	const layoutProps = {
		actions: {
			openGetAQuote,
			closeGetAQuote,
		},
	}
	const currentRoute = (navigation || [])
		.find(({ to, exact }) => (exact ? location.pathname === to : location.pathname.match(new RegExp(`${ to }.*`, 'ig')))) || { name: 'Not Found', title: 'Not Found' }
	return (
		<GlobalProvider { ...layoutProps } currentRoute={ currentRoute }>
			<Helmet>
				<title>{ currentRoute.title }</title>
				<link rel="canonical" href="http://www.jimmyboys.pt" />
			</Helmet>
			<div className={ `${ styles['app'] } ${ showGetAQuote ? styles['is-pushed-back'] : '' }` }>
				<LMenu logo={ logo } navigation={ navigation } />
				<main className={ styles['main'] }>
					<Switch>
						<Route path="/" exact component={ Homepage } />
						<Route path="/ui-ux-development" exact component={ UiUxDevelopment } />
						<Route path="/apis-programming" exact component={ ApisProgramming } />
						<Route path="/about-us" exact component={ About } />
						<Route path="*" exact component={ NoPage } />
					</Switch>
				</main>

				<Layout
					modifiers={ [
						'--root-background-footer-darker',
						'--root-width-normal',
						'--content-padding-vertical-small',
						'--content-padding-horizontal-big'
					] }
				>
					<CallToAction title='Feel free to contact us' subtitle={ `If you have any doubts, please check out our [portfolio](/about-us#portfolio).` } />
				</Layout>
				<Footer
					modifiers={ [
						'--root-background-footer',
						'--root-width-normal',
						'--content-padding-top-big',
						'--content-padding-horizontal-big'
					] }
					logo={ logo }
					copyright="2023"
					mailContact="what@jimmyboys.pt"
					slogan={ '[ Commitment & Rock\'n\'Roll ]' }
					navigation={ navigation }
					externalLinks={ externalLinks }
					socialLinks={ socialLinks }
				/>
			</div>
			{ showGetAQuote && <div className={ styles['backdrop'] } ref={ backdrop } /> }
			{ showGetAQuote && <GetAQuote className={ styles['contact-us-window'] } /> }
		</GlobalProvider>
	)
}

export default App
