import styles from './GetAQuote.module.sass'

import React, { useState } from 'react'
import { useGlobalValue } from '../../controllers/GlobalContext'

import sound from '../../assets/send.mp3'
import { ReactComponent as Frontend } from '../../assets/frontend.svg'
import { ReactComponent as Api } from '../../assets/api.svg'
import { ReactComponent as Both } from '../../assets/frontend-api.svg'
import { ReactComponent as CheckMark } from '../../assets/checkmark.svg'
import { ReactComponent as SmallBudget } from '../../assets/small-budget.svg'
import { ReactComponent as MediumBudget } from '../../assets/medium-budget.svg'
import { ReactComponent as LargeBudget } from '../../assets/large-budget.svg'
import Button from '../../components/inputs/Button/Button'
const audio = new Audio(sound)
audio.volume = 0.2

const projects = [
	{
		name: 'Frontend',
		icon: Frontend,
		value: 'Frontend'
	},
	{
		name: 'API',
		icon: Api,
		value: 'API'
	},
	{
		name: 'Both',
		icon: Both,
		value: 'Both'
	}
]
const budgets = [
	{
		name: '5K € - 20K €',
		icon: SmallBudget,
		value: '5K € - 20K €'
	},
	{
		name: '20K € - 50K €',
		icon: MediumBudget,
		value: '20K € - 50K €',
	},
	{
		name: '> 50K €',
		icon: LargeBudget,
		value: '> 50K €'
	}
]

const frontEndTechnologies = [
	'React',
	'Electron',
	'SwiftUI',
	'Flutter'
]

const apiTechnologies = [
	'REST',
	'GraphQL',
	'Push',
	'Push Notifications',
	'OAuth',
	'Payments'
]

const renderOptions = (types, group, data, onChange) => {
	return types.map(({ name, value, icon: Icon }, index) => {
		return (
			<label key={ index } className={ styles['radio'] }>
				<input
					type="radio"
					name={ group }
					value={ value }
					checked={ value === data }
					onChange={ onChange }
				/>
				<div>
					<div>
						<CheckMark className={ styles['checkmark'] } />
						<Icon className={ styles['icon'] } />
					</div>
					<small className={ styles['label'] }>{ name }</small>
				</div>
			</label>
		)
	})
}

const renderTechnologies = (technologies, data, setData) => {
	const list = technologies.map((technology, index) => {
		const selectionIndex = data.technologies.findIndex(t => t === technology)
		return (
			<label key={ index } className={ styles['checkbox'] }>
				<input type="checkbox" checked={ selectionIndex >= 0 } onChange={() => {
					if (selectionIndex >= 0) {
						const selectedTechnologies = [...data.technologies]
						selectedTechnologies.splice(selectionIndex, 1)
						setData({ ...data, technologies: selectedTechnologies })
					} else {
						const selectedTechnologies = [...data.technologies]
						selectedTechnologies.push(technology)
						setData({ ...data, technologies: selectedTechnologies })
					}
				}} />
				<div>
					<CheckMark />
					<small>{ technology }</small>
				</div>
			</label>
		)
	})
	const remaining = (Math.ceil(technologies.length/3) * 3) - technologies.length
	for (let index = 0; index < remaining; index++) {
		list.push(<span key={ `r_${ index }` }/>)
	}
	return list
}

const getMessageBody = (data) => {
	return `Name
${ data.name }

Date
${ data.release }

Description
${ data.description }

Requirements
${ data.requirements ? 'We have all the functional requirements.' : 'We need help with the functional requirements.' }

Budget
${ data.budget }

Project Type
${ data.project }

Technologies
${ data.technologies.join(', ') }
`
}

const GetAQuote = (props) => {
	const { className } = props
	const { actions } = useGlobalValue()
	const [error, setError] = useState(null)
	const [data, setData] = useState({
		name: '',
		release: '',
		description: '',
		requirements: false,
		project: projects[0].value,
		budget: budgets[0].value,
		technologies: []
	})
	const [submitted, setSubmitted] = useState(false)

	const checkIfValid = () => {
		return data.name.length && data.description.length && data.release.length 
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		const isValid = checkIfValid() //e.target.checkValidity ? e.target.checkValidity() : checkIfValid()
		if (isValid) {
			setSubmitted(true)
			audio.play()
			setTimeout(() => {
				const to = 'what@jimmyboys.pt'
				const subject = 'Quote Request'
				const body = getMessageBody(data)
				window.open(`mailto:${ to }?subject=${ subject }&body=${ encodeURIComponent(body) }`)
				actions.closeGetAQuote && actions.closeGetAQuote()
			}, 200)
		} else {
			setError('Please fill all the inputs.')
		}
	}
	return (
		<div className={ `${ styles['root'] } ${ className || '' } ${ submitted ? styles['is-submitted'] : '' }` }>
			<div className={ styles['content'] }>
				<div className={ styles['header'] }>
					<h3>Get a quote</h3>
					<button className={ styles['close-button'] } onClick={ actions.closeGetAQuote }><strong>Cancel</strong></button>
				</div>
				<div className={ styles['form-container'] }>
					<form onSubmit={ handleSubmit }>
						<h4>What is your name?</h4>
						<input
							className={ styles['text-input'] }
							type='text'
							placeholder='Insert your name'
							value={ data.name }
							required
							onChange={ (e) => {
								setError(null)
								setData({...data, name: e.target.value })
							} }
						/>
						<h4>What is the release date?</h4>
						<input
							className={ styles['text-input'] }
							type='text'
							placeholder='Insert a date (DD/MM/YYYY)'
							value={ data.release }
							required
							onChange={ (e) => {
								setError(null)
								setData({...data, release: e.target.value })
							} }
						/>
						<h4>What is your project?</h4>
						<textarea
							className={ styles['text-area'] }
							type='text'
							placeholder='Insert a small description of your project'
							value={ data.description }
							required
							onChange={ (e) => {
								setError(null)
								setData({...data, description: e.target.value })
							} }
						/>
						<label className={ styles['simple-checkbox'] }>
							<input type="checkbox" checked={ data.requirements } onChange={ (e) => {
								setData({...data, requirements: !data.requirements })
							} }/>
							<span>
								<span className={ styles['checkbox-icon'] }>
									<CheckMark />
								</span>
								<small>I have all the functional requirements.</small>
							</span>
						</label>
						<h4>What is your budget?</h4>
						<div className={ styles['horizontal-options'] }>
							{ renderOptions(budgets, 'budget', data.budget, (e) => {
								setData({ ...data, budget: e.target.value })
							}) }
						</div>
						<h4>What type of project do you need?</h4>
						<div className={ styles['horizontal-options'] }>
							{ renderOptions(projects, 'projects', data.project, (e) => {
								setData({ ...data, technologies: [], project: e.target.value })
							}) }
						</div>
						<h4>Which technologies do you want to use?</h4>
						<div className={ styles['options-list'] }>
							{ new Set(['Both', 'Frontend']).has(data.project) && renderTechnologies(frontEndTechnologies, data, setData) }
							{ new Set(['Both', 'API']).has(data.project) && renderTechnologies(apiTechnologies, data, setData) }
						</div>
						{ error && <div className={ styles['error-message'] }><small>{ error }</small></div> }
						<div className={ styles['submit-container'] }>
							<Button primary>Send Email</Button>
						</div>
					</form>
				</div>
			</div>
		</div>
	)
}

export default GetAQuote
