import React from 'react'
import Layout from '../components/Layout/Layout'

import noPageImg from '../assets/404.svg'
import Media from '../components/Media/Media'
import SectionSummary from '../components/SectionSummary/SectionSummary'

const NoPage = () => {
	return (
		<div>
			<Layout
				size={ '80vh' }
				direction='row'
				columns={ [1, 1] }
				alignments={ ['stretch stretch', 'center center'] }
				modifiers={ [
					'--root-background-diagonal-gradient',
					'--root-width-normal',
					'--content-padding-horizontal-biggest',
					'--content-padding-top-big',
					'--content-padding-bottom-big',
					'--content-wrap-normal',
				] }
			>
				<Layout aspectRatio='4:3'  modifiers={ ['--content-padding-top-normal'] }>
					<Media modifiers={ ['--image-size-contain'] } src={ noPageImg } />
				</Layout>
				<Layout>
					<SectionSummary
						modifiers={ ['--title-decoration-underlined', '--content-text-align-center'] }
						title='Not Found'
						summary='There is nothing to see here. This page was probably moved or never existed. Please visit our [homepage](/).'
					/>
				</Layout>
			</Layout>

		</div>
	)
}

export default NoPage