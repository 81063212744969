import React from 'react'
import ReactMarkdown from 'react-markdown'
import { HashLink as Link } from 'react-router-hash-link'

const renderers = () => {
	return {
		link: props => {
			if (props.href.match(/^(https?:|\/\/)/)) {
				return (
					<a href={props.href} target="_blank" rel="noopener noreferrer">{ props.children }</a>
				)
			}
			return (
				<Link to={props.href}>{ props.children }</Link>
			)
		}
	}
}

const Markdown = (props) => {
	const { children, source } = props
	return (
		<ReactMarkdown
			source={ source }
			renderers={ renderers() }
		>{ children }</ReactMarkdown>
	)
}

export default Markdown