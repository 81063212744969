import styles from './VerticalThumbsCarousel.module.sass'

import 'intersection-observer'

import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import Markdown from '../Markdown/Markdown'
import Layout from '../Layout/Layout'

const SLIDE_TRANSITION_TIME = 6000

const VerticalThumbsCarousel = (props) => {
	const { children, modifiers } = props
	const [currentSlide, setCurrentSlide] = useState(0)
	const [visible, setVisible] = useState(false)
	const [animating, setAnimating] = useState(false)
	const [isHover, setIsHover] = useState(false)
	const element = useRef(null)

	useEffect(() => {
		let timeout
		if (!animating && !isHover && visible) {
			timeout = setTimeout(() => {
				setAnimating(true)
			}, 10)
		}
		return () => {
			clearTimeout(timeout)
		}
	}, [currentSlide, visible, animating, isHover])

	useEffect(() => {
		const timeout = !isHover && visible ? setTimeout(() => {
			setAnimating(false)
			setCurrentSlide(currentSlide < (children[0] || []).length - 1 ? currentSlide + 1 : 0)
		}, SLIDE_TRANSITION_TIME) : null
		return () => {
			clearTimeout(timeout)
		}
	}, [children, currentSlide, isHover, visible])

	useEffect(() => {
		const elem = element.current
		if (!elem) { return }
		const handleIntersection = (e) => {
			if (!e[0].intersectionRatio) {
				setVisible(false)
			} else {
				setVisible(true)
				setAnimating(false)
				setIsHover(false)
			}
		}
		let observer = new IntersectionObserver(
			handleIntersection,
			{
				marginTop: 0,
				offset: [0, 1]
			}
		)
		observer.observe(elem)
		return () => {
			observer.unobserve(elem)
		};
	}, [element])

	const thumbs = (children[0] || []).map(({ title }, index) => (
		<div
			key={ index }
			className={ `${ styles['thumb-title'] } ${ index === currentSlide ? styles['is-selected'] : '' }` }
		>
			<Link
				to={ { search: `#slide_${ index }` } }
				onClick={ () => {
					setCurrentSlide(index)
				} }
			>{ title }</Link>
		</div>
	))
	const slides = (children[1] || []).map(({ title, summary }, index) => (
		index === currentSlide && (
			<div key={ index } className={ styles['slide'] }>
				<h1 className={ styles['slide-title'] }>{ title }</h1>
				<Markdown className={ styles['slide-text'] }>{ summary }</Markdown>
			</div>
		)
	))
	return (
		<Layout
			className={ `${ styles['root'] } ${ (modifiers || []).map(m => styles[m] || '').join(' ') }` }
			forwardRef={ element }
			modifiers={ [
				'--content-wrap-reverse',
				'--content-gap-normal'
			] }
			direction='row'
			columns={ [1, 1] }
		>
			<div className={ styles['thumbs'] }>
				<div
					className={ `${ styles['slide-number'] } ${ animating ? styles['is-animating'] : '' }` }
					style={ { animationDuration: `${ SLIDE_TRANSITION_TIME }ms` } }
				>
					<h4>/{ ('0' + (currentSlide + 1)).slice(-2) }</h4>
				</div>
				<div
					onMouseEnter={ () => {
						setIsHover(true)
						setAnimating(false)
					} }
					onMouseLeave={ () => {
						setIsHover(false)
					} }
				>
					{ thumbs }
				</div>
			</div>
			<div className={ styles['content'] }>
				{ slides }
			</div>
		</Layout>
	)
}

export default VerticalThumbsCarousel
