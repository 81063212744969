import styles from './ScrollIndicator.module.sass'

import React from 'react'
import smoothscroll from 'smoothscroll-polyfill'

smoothscroll.polyfill()

const ScrollIndicator = () => {
	const scrollOnePage = () => {
		window.scrollTo({
			top: window.innerHeight,
			behavior: 'smooth',
		})
	}
	return (
		<span onClick={ scrollOnePage } className={ styles['mouse'] }>
			<span className={ styles['border'] } />
			<span className={ styles['scroll'] } />
		</span>
	)
}

export default ScrollIndicator