import styles from './AppearController.module.sass'
import React, { useRef, useState, useEffect } from 'react'
import 'intersection-observer'

const AppearController = (props) => {
	const { children, className, ...otherProps } = props
	const element = useRef(null)
	const [visible, setVisible] = useState(true)
	useEffect(() => {
		const elem = element.current
		if (!elem) { return }
		const handleIntersection = (e) => {
			if (!e[0].intersectionRatio) {
				setVisible(false)
			} else {
				setVisible(true)
			}
		}
		let observer = new IntersectionObserver(
			handleIntersection,
			{
				rootMargin: '0px',
  				threshold: 0.5
			}
		)
		observer.observe(elem)
		return () => {
			observer.unobserve(elem)
		};
	}, [element])
	
	return (
		<div
			ref={ element }
			className={ `${ styles['root'] } ${ visible ? '' : styles['hidden'] } ${ className || '' }` }
			{ ...otherProps }
		>{ children }</div>
	)
}

export default AppearController
