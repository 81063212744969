import React from 'react'
import Layout from '../components/Layout/Layout'
import Animator from '../components/Animator/Animator'

import about from '../assets/about-animation-all.svg'
import aboutMobile from '../assets/about-animation-all@mobile.svg'
import ScrollIndicator from '../components/ScrollIndicator/ScrollIndicator'
import SectionSummary from '../components/SectionSummary/SectionSummary'
import Media from '../components/Media/Media'

import colbi from '../assets/colbi.jpg'
import meoMusic from '../assets/meo music.jpg'
import tvg from '../assets/tvg.jpg'
import yads from '../assets/yads.jpg'
import beapt from '../assets/beapt.jpg'
import nmusicTV from '../assets/nmusicTV.jpg'
import yweb from '../assets/yweb.jpg'
import kk from '../assets/kk.jpg'

const keyframes = [
	{
		source: 0,
		element: 'wheel',
		end: 1,
		animation: 'transform',
		values: {
			from: {
				rotate: [0, 33, 33]
			},
			to: {
				rotate: [10024, 33, 33]
			}
		}
	},
	{
		source: 0,
		element: 'sidewalk',
		start: 0,
		end: 1,
		steps: 25,
		animation: 'transform',
		values: {
			from: {
				translate: [0, 0]
			},
			to: {
				translate: [-400, 0]
			}
		}
	},
	{
		source: 0,
		element: 'hair',
		start: 0,
		end: 1,
		steps: 80,
		animation: 'transform',
		values: {
			from: {
				rotate: [0, 0, 0],
				scale: [1, 1],
				translate: [0, 0]
			},
			to: {
				rotate: [-2, 0, 50],
				scale: [0.96, 1.06],
				translate: [1, -2]
			}
		}
	},
	{
		source: 0,
		element: 'hair2',
		start: 0,
		end: 1,
		steps: 90,
		animation: 'transform',
		values: {
			from: {
				rotate: [0, 0, 0]
			},
			to: {
				rotate: [-2, 0, 50]
			}
		}
	},
	{
		source: 0,
		element: 'jacket2',
		start: 0,
		end: 1,
		steps: 50,
		animation: 'transform',
		values: {
			from: {
				rotate: [0, 0, 0]
			},
			to: {
				rotate: [10, 10, 0]
			}
		}
	},
	{
		source: 0,
		element: 'based',
		start: 0,
		end: 0.02,
		animation: 'transform',
		values: {
			from: {
				translate: [0, 0]
			},
			to: {
				translate: [20, 0]
			}
		}
	},
	{
		source: 0,
		element: 'based',
		start: 0.02,
		end: 0.3,
		animation: 'transform',
		// values: {
		// 	from: {
		// 		translate: [20, 0]
		// 	},
		// 	to: {
		// 		translate: [-4040, 0]
		// 	}
		// },
		valuesSet: {
			mobile: {
				from: {
					translate: [20, 0]
				},
				to: {
					translate: [-4040, 0]
				}
			},
			all: {
				from: {
					translate: [20, 0]
				},
				to: {
					translate: [-4040, 0]
				}
			}
		}
	},
	{
		source: 0,
		element: 'clerigos',
		start: 0,
		end: 0.3,
		animation: 'transform',
		values: {
			from: {
				translate: [0, 0]
			},
			to: {
				translate: [-3040, 0]
			}
		}
	},
	{
		source: 0,
		element: 'clerigos',
		start: 0.3,
		end: 0.3,
		animation: 'display',
		values: {
			from: 'inline',
			to: 'none'
		}
	},
	{
		source: 0,
		element: 'based',
		start: 0.3,
		end: 0.3,
		animation: 'display',
		values: {
			from: 'inline',
			to: 'none'
		}
	},
	{
		source: 0,
		element: 'ideas',
		start: 0.12,
		end: 0.12,
		animation: 'display',
		values: {
			from: 'none',
			to: 'inline',
		}
	},
	{
		source: 0,
		element: 'ideas',
		start: 0.12,
		end: 0.26,
		animation: 'transform',
		valuesSet: {
			mobile: {
				from: {
					translate: [0, 0]
				},
				to: {
					translate: [-624, 0]
				}
			},
			all: {
				from: {
					translate: [0, 0]
				},
				to: {
					translate: [-1120, 0]
				}
			}
		}
	},
	{
		source: 0,
		element: 'ideas',
		start: 0.3,
		end: 0.32,
		animation: 'transform',
		valuesSet: {
			mobile: {
				from: {
					translate: [-624, 0]
				},
				to: {
					translate: [-604, 0]
				}
			},
			all: {
				from: {
					translate: [-1120, 0]
				},
				to: {
					translate: [-1100, 0]
				}
			}
		}
	},
	{
		source: 0,
		element: 'ideas',
		start: 0.32,
		end: 0.5,
		animation: 'transform',
		valuesSet: {
			mobile: {
				from: {
					translate: [-604, 0]
				},
				to: {
					translate: [-4070, 0]
				}
			},
			all: {
				from: {
					translate: [-1100, 0]
				},
				to: {
					translate: [-4070, 0]
				}
			}
		}
	},
	{
		source: 0,
		element: 'musica',
		start: 0.18,
		end: 0.18,
		animation: 'display',
		values: {
			from: 'none',
			to: 'inline'
		}
	},
	{
		source: 0,
		element: 'musica',
		start: 0.18,
		end: 0.48,
		animation: 'transform',
		values: {
			from: {
				translate: [0, 0]
			},
			to: {
				translate: [-3070, 0]
			}
		}
	},
	{
		source: 0,
		element: 'musica',
		start: 0.48,
		end: 0.48,
		animation: 'display',
		values: {
			from: 'inline',
			to: 'none'
		}
	},
	{
		source: 0,
		element: 'ideas',
		start: 0.48,
		end: 0.48,
		animation: 'display',
		values: {
			from: 'inline',
			to: 'none'
		}
	},
	{
		source: 0,
		element: 'rust',
		start: 0.4,
		end: 0.4,
		animation: 'display',
		values: {
			from: 'none',
			to: 'inline',
		}
	},
	{
		source: 0,
		element: 'rust',
		start: 0.4,
		end: 0.5,
		animation: 'transform',
		valuesSet: {
			mobile: {
				from: {
					translate: [0, 0]
				},
				to: {
					translate: [-624, 0]
				}
			},
			all: {
				from: {
					translate: [0, 0]
				},
				to: {
					translate: [-1120, 0]
				}
			}
		}
	},
	{
		source: 0,
		element: 'rust',
		start: 0.575,
		end: 0.585,
		animation: 'transform',
		valuesSet: {
			mobile: {
				from: {
					translate: [-624, 0]
				},
				to: {
					translate: [-604, 0]
				}
			},
			all: {
				from: {
					translate: [-1120, 0]
				},
				to: {
					translate: [-1100, 0]
				}
			}
		}
	},
	{
		source: 0,
		element: 'rust',
		start: 0.585,
		end: 0.76,
		animation: 'transform',
		valuesSet: {
			mobile: {
				from: {
					translate: [-604, 0]
				},
				to: {
					translate: [-4070, 0]
				}
			},
			all: {
				from: {
					translate: [-1100, 0]
				},
				to: {
					translate: [-4070, 0]
				}
			}
		}
	},
	{
		source: 0,
		element: 'rust',
		start: 0.76,
		end: 0.76,
		animation: 'display',
		values: {
			from: 'inline',
			tp: 'none',
		}
	},
	{
		source: 0,
		element: 'javascript',
		start: 0.6,
		end: 0.6,
		animation: 'display',
		values: {
			from: 'none',
			to: 'inline',
		}
	},
	{
		source: 0,
		element: 'javascript',
		start: 0.6,
		end: 0.7,
		animation: 'transform',
		valuesSet: {
			mobile: {
				from: {
					translate: [0, 0]
				},
				to: {
					translate: [-624, 0]
				}
			},
			all: {
				from: {
					translate: [0, 0]
				},
				to: {
					translate: [-1120, 0]
				}
			}
		}
	},
	{
		source: 0,
		element: 'javascript',
		start: 0.76,
		end: 0.78,
		animation: 'transform',
		valuesSet: {
			mobile: {
				from: {
					translate: [-624, 0]
				},
				to: {
					translate: [-604, 0]
				}
			},
			all: {
				from: {
					translate: [-1120, 0]
				},
				to: {
					translate: [-1100, 0]
				}
			}
		}
	},
	{
		source: 0,
		element: 'javascript',
		start: 0.78,
		end: 0.88,
		animation: 'transform',
		valuesSet: {
			mobile: {
				from: {
					translate: [-604, 0]
				},
				to: {
					translate: [-4070, 0]
				}
			},
			all: {
				from: {
					translate: [-1100, 0]
				},
				to: {
					translate: [-4070, 0]
				}
			}
		}
	},
	{
		source: 0,
		element: 'javascript',
		start: 0.88,
		end: 0.88,
		animation: 'display',
		values: {
			from: 'inline',
			tp: 'none',
		}
	},
	{
		source: 0,
		element: 'bridge',
		start: 0.46,
		end: 0.46,
		animation: 'display',
		values: {
			from: 'none',
			to: 'inline'
		}
	},
	{
		source: 0,
		element: 'bridge',
		start: 0.46,
		end: 0.88,
		animation: 'transform',
		values: {
			from: {
				translate: [0, 0]
			},
			to: {
				translate: [-4200, 0]
			}
		}
	},
	{
		source: 0,
		element: 'bridge',
		start: 0.88,
		end: 0.88,
		animation: 'display',
		values: {
			from: 'inline',
			to: 'none',
		}
	},
	{
		source: 0,
		element: 'java',
		start: 0.6,
		end: 0.6,
		animation: 'display',
		values: {
			from: 'none',
			to: 'inline',
		}
	},
	{
		source: 0,
		element: 'java',
		start: 0.6,
		end: 0.84,
		animation: 'transform',
		values: {
			from: {
				translate: [0, 0]
			},
			to: {
				translate: [-2400, 0]
			}
		}
	},
	{
		source: 0,
		element: 'wedo',
		start: 0.8,
		end: 0.8,
		animation: 'display',
		values: {
			from: 'none',
			to: 'inline',
		}
	},
	{
		source: 0,
		element: 'wedo',
		start: 0.8,
		end: 0.9,
		animation: 'transform',
		valuesSet: {
			mobile: {
				from: {
					translate: [0, 0]
				},
				to: {
					translate: [-624, 0]
				}
			},
			all: {
				from: {
					translate: [0, 0]
				},
				to: {
					translate: [-1120, 0]
				}
			}
		}
	},
]

const About = () => {
	return (
		<div>
			<Layout
				size='1400vh'
				modifiers={ [
					'--content-display-stacked-behind'
				] }
			>
				<Layout>
					<Layout
						modifiers={ ['--root-position-sticky-top'] }
						size={ '100vh' }
					>
						<Animator
							endOfWindowOffset={ false }
							imagesSet={ {
								mobile: [aboutMobile],
								all:[about]
							} }
							keyframes={ keyframes }
						/>
					</Layout>
				</Layout>
				<Layout
					size='80vh'
					modifiers={ [
						'--root-position-sticky-bottom',
						'--root-width-normal',
						'--content-padding-horizontal-big',
						'--content-justify-flex-end',
						'--content-align-center'
					] }
				>
					<ScrollIndicator />
				</Layout>
			</Layout>
			<Layout
				id="portfolio"
				direction='row'
				columns={[2, 3]}
				alignments={ ['stretch center', 'stretch center'] }
				modifiers={ [
					'--root-width-normal',
					'--content-padding-vertical-normal',
					'--content-padding-bottom-small',
					'--content-padding-horizontal-big'
				] }
			>
				<SectionSummary
					modifiers={ ['--title-decoration-underlined'] }
					title="Our Portfolio"
					summary={`Along the way, we have been working with **different technologies** and **different business needs**. This helped us **grow** and prepared us for **more demanding projects**.`}
				/>
			</Layout>
			<Layout
				appear
				direction='row'
				columns={[3, 2]}
				alignments={ ['stretch center', 'stretch center'] }
				modifiers={ [
					'--root-width-normal',
					'--content-wrap-reverse',
					'--content-padding-vertical-normal',
					'--content-padding-horizontal-big',
					'--content-gap-normal'
				] }
			>
				<SectionSummary
					smallTitle
					title="2019 - Petapilot"
					summary={`Col.bi is an online **financial audit** platform. We have **redesigned** both the UI and the user experience and built a **custom frontend framework** using React. Alongside we have create a frontend GraphQL API using typescript.`}
				/>
				<Layout aspectRatio='16:9'>
					<Media modifiers={ ['--image-size-cover'] } src={ colbi } />
				</Layout>
			</Layout>
			<Layout
				appear
				direction='row-reverse'
				columns={[3, 2]}
				alignments={ ['stretch center', 'stretch center'] }
				modifiers={ [
					'--root-width-normal',
					'--content-wrap-reverse',
					'--content-padding-vertical-normal',
					'--content-padding-horizontal-big',
					'--content-gap-normal'
				] }
			>
				<SectionSummary
					smallTitle
					title="2018 - yMedia"
					summary={`yAds is a **campaign analytics platform** for digital marketing. We have build a platform that **periodically collects data** from [Google Ads][https://ads.google.com/] and [Facebook Ads](https://business.facebook.com/), **standardize the data** for better analysis and **triggers alerts**.`}
				/>
				<Layout aspectRatio='16:9'>
					<Media modifiers={ ['--image-size-cover'] } src={ yads } />
				</Layout>
			</Layout>
			<Layout
				appear
				direction='row'
				columns={[3, 2]}
				alignments={ ['stretch center', 'stretch center'] }
				modifiers={ [
					'--root-width-normal',
					'--content-wrap-reverse',
					'--content-padding-vertical-normal',
					'--content-padding-horizontal-big',
					'--content-gap-normal'
				] }
			>
				<SectionSummary
					smallTitle
					title="2018 - beApt"
					summary={`We have build a **platform for trainers** that offers several services such as prescribing personalised training, monitoring, endurance and efficiency assessments. The platform also handles **payments** and **subscriptions**.`}
				/>
				<Layout aspectRatio='16:9'>
					<Media modifiers={ ['--image-size-cover'] } src={ beapt } />
				</Layout>
			</Layout>
			<Layout
				appear
				direction='row-reverse'
				columns={[3, 2]}
				alignments={ ['stretch center', 'stretch center'] }
				modifiers={ [
					'--root-width-normal',
					'--content-wrap-reverse',
					'--content-padding-vertical-normal',
					'--content-padding-horizontal-big',
					'--content-gap-normal'
				] }
			>
				<SectionSummary
					smallTitle
					title="2017 - nMusic"
					summary={`We have **design** and **rebuild** all the frontend applications (desktop, ios and android) for nMusic **music streaming service**. The desktop application was build using electron and the mobile apps were built natively.`}
				/>
				<Layout aspectRatio='16:9'>
					<Media modifiers={ ['--image-size-cover'] } src={ meoMusic } />
				</Layout>
			</Layout>
			<Layout
				appear
				direction='row'
				columns={[3, 2]}
				alignments={ ['stretch center', 'stretch center'] }
				modifiers={ [
					'--root-width-normal',
					'--content-wrap-reverse',
					'--content-padding-vertical-normal',
					'--content-padding-horizontal-big',
					'--content-gap-normal'
				] }
			>
				<SectionSummary
					smallTitle
					title="2016 - yMedia"
					summary={`yWeb is a **CMS driven website builder/manager**. We have **design** both the **architecture** and built the application and its services. Alongside we have build several website templates.`}
				/>
				<Layout aspectRatio='16:9'>
					<Media modifiers={ ['--image-size-cover'] } src={ yweb } />
				</Layout>
			</Layout>
			<Layout
				appear
				direction='row-reverse'
				columns={[3, 2]}
				alignments={ ['stretch center', 'stretch center'] }
				modifiers={ [
					'--root-width-normal',
					'--content-wrap-reverse',
					'--content-padding-vertical-normal',
					'--content-padding-horizontal-big',
					'--content-gap-normal'
				] }
			>
				<SectionSummary
					smallTitle
					title="2016 - KuantoKusta"
					summary={`We have design all the **mobile experience** for the KuantoKusta application. We have build both **iOS and Android** applications using platform native languages (Swift and Java).`}
				/>
				<Layout aspectRatio='16:9'>
					<Media modifiers={ ['--image-size-cover'] } src={ kk } />
				</Layout>
			</Layout>
			<Layout
				appear
				direction='row'
				columns={[3, 2]}
				alignments={ ['stretch center', 'stretch center'] }
				modifiers={ [
					'--root-width-normal',
					'--content-wrap-reverse',
					'--content-padding-vertical-normal',
					'--content-padding-horizontal-big',
					'--content-gap-normal'
				] }
			>
				<SectionSummary
					smallTitle
					title="2015 - TVG"
					summary={`We have **redesign** all the **user experience** of TVG mobile applications (Horse Racing Betting). We have develop an responsive **iOS** application for **iPhone and iPad**. We have also develop the **web mobile application**.`}
				/>
				<Layout aspectRatio='16:9'>
					<Media modifiers={ ['--image-size-cover'] } src={ tvg } />
				</Layout>
			</Layout>
			<Layout
				appear
				direction='row-reverse'
				columns={[3, 2]}
				alignments={ ['stretch center', 'stretch center'] }
				modifiers={ [
					'--root-width-normal',
					'--content-wrap-reverse',
					'--content-padding-vertical-normal',
					'--content-padding-horizontal-big',
					'--content-gap-normal'
				] }
			>
				<SectionSummary
					smallTitle
					title="2014 - nMusic"
					summary={`We have **design** the user experience for the music streaming service on **TV devices**. We have also develop the application for **Samsung SmartTV** platform.`}
				/>
				<Layout aspectRatio='16:9'>
					<Media modifiers={ ['--image-size-cover'] } src={ nmusicTV } />
				</Layout>
			</Layout>
		</div>
	)
}

export default About
