import styles from './Footer.module.sass'

import React from 'react'
import Layout from '../Layout/Layout'
import Media from '../Media/Media'
import { Link } from 'react-router-dom'
import Icon from '../generics/Icon'

const Footer = (props) => {
	const { modifiers, logo, slogan, copyright, mailContact, navigation, externalLinks, socialLinks } = props
	return (
		<Layout modifiers={ modifiers }>
			<footer className={ styles['root'] }>
				<div className={ styles['details'] }>
					<div className={ styles['logo-container'] }>
						{ logo && <div className={ styles['logo'] }>
							<Media modifiers={ ['--image-position-left-center'] } src={ logo } />
						</div> }
						{ slogan && <h4>{ slogan }</h4> }
					</div>
					<div>
						<Layout
							direction='row'
							columns={ [1, 2, 1] }
							alignments={ ['stretch stretch', 'stretch stretch', 'stretch stretch'] }
							className={ styles['links-container'] }
							modifiers={ ['--content-gap-normal'] }
						>
							<div>
								<h4 className={ styles['inner-title'] }>Sitemap</h4>
								<div>
									{ (navigation || []).map(({ to, name }, index) => (
										<p key={ index }>
											<Link to={ to }><small>{ name }</small></Link>
										</p>
									)) }
								</div>
							</div>
							<div>
								<h4 className={ styles['inner-title'] }>Other Links</h4>
								<div className={ styles['external-links'] }>
									{ (externalLinks || []).map(({ to, name }, index) => (
										<p key={ index }>
											<a href={ to } target="_blank" rel="noopener noreferrer"><small>{ name }</small></a>
										</p>
									)) }
								</div>
							</div>
							<div>
								<h4 className={ styles['inner-title'] }>Social Networks</h4>
								<div className={ styles['social-links'] }>
									{ (socialLinks || []).map(({ to, icon }, index) => (
										<span key={ index }>
											<a href={ to } target="_blank" rel="noopener noreferrer">
												<Icon className={ styles['social-icon'] } name={ icon } />
											</a>
										</span>
									)) }
								</div>
							</div>
						</Layout>
					</div>
				</div>
				<div className={ styles['info-bar'] }>
					<div><small>Copyright© { copyright }</small></div>
					<div><small><a href={ `mailto:${ mailContact }` } target="_blank" rel="noopener noreferrer">{ mailContact }</a></small></div>
				</div>
			</footer>
		</Layout>
	)
}

export default Footer