import React from 'react'

import Layout from '../components/Layout/Layout'
import Media from '../components/Media/Media'
import ScrollIndicator from '../components/ScrollIndicator/ScrollIndicator'
import SectionSummary from '../components/SectionSummary/SectionSummary'

import Grid from '../components/Grid/Grid'
import SimpleCard from '../components/cards/SimpleCard/SimpleCard'

import headerBg from '../assets/ui-ux-development.svg'
import headerBgMobile from '../assets/ui-ux-development@mobile.svg'

import colbi from '../assets/colbi.jpg'
import meoMusic from '../assets/meo music.jpg'
import tvg from '../assets/tvg.jpg'

import reactIcon from '../assets/react-icon.svg'
import webassemblyIcon from '../assets/webassembly-icon.svg'
import electronIcon from '../assets/electron-icon.svg'
import pwaIcon from '../assets/pwa-icon.svg'
import swiftIcon from '../assets/swift-icon.svg'
import flutterIcon from '../assets/flutter-icon.svg'
import frontendTechnologies from '../assets/frontend-technologies.svg'


const UiUxDevelopment = () => {
	return (
		<>
			<Layout
				modifiers={ [
					'--root-background-diagonal-gradient',
					'--content-display-stacked-behind'
				] }
			>
				<Layout
					size={ '100vh' }
				>
					<Media modifiers={ ['--image-position-center-bottom', '--image-size-cover'] } srcSet={ { 'mobile': headerBgMobile, 'all': headerBg } } />
				</Layout>
				<Layout
					size={ '100vh' }
					direction='column'
					modifiers={ [
						'--content-justify-center'
					] }
				>
					<Layout
						direction='row'
						columns={ [4, 4] }
						modifiers={ [
							'--root-width-normal',
							'--content-padding-horizontal-big',
							'--content-padding-vertical-big',
							'--content-margin-vertical-big'
						] }
					>
						<Layout
							modifiers={ [
								'--content-padding-right-normal'
							] }
						>
							<SectionSummary
								modifiers={ ['--title-decoration-underlined'] }
								title='UI/UX Development'
								summary={ `We help you **design** and **develop** both your **user interface** and **user experience** following the most recent trends.` }
							/>
							<Layout aspectRatio='120:10' modifiers={ ['--content-margin-top-tiny'] }>
								<Media src={ frontendTechnologies } modifiers={ ['--image-position-left-center'] } />
							</Layout>
						</Layout>
					</Layout>
					<Layout
						modifiers={ [
							'--root-position-sticky-bottom',
							'--root-width-normal',
							'--content-padding-horizontal-big',
							'--content-justify-center',
							'--content-align-center'
						] }
					>
						<ScrollIndicator />
					</Layout>
				</Layout>
			</Layout>
			<Layout
				direction='row'
				columns={ [4, 5] }
				modifiers={ [
					'--root-width-normal',
					'--content-padding-vertical-normal',
					'--content-padding-bottom-small',
					'--content-padding-horizontal-big'
				] }
			>
				<SectionSummary
					modifiers={ ['--title-decoration-underlined'] }
					title="Technologies"
					summary={ `We love to work with the most **recent technologies**, **frameworks** and **development approaches**. **We have mastered** the following technologies and **now** we **use** and **teach** them.` }
				/>
			</Layout>
			<Layout
				modifiers={ [
					'--root-width-normal',
					'--content-padding-horizontal-big',
					'--content-padding-bottom-normal',
				] }
			>
				<Grid
					cards={ [SimpleCard] }
					modifiers={ ['--grip-gap-big'] }
					cardsModifiers={ [[]] }
				>
					{
						[
							{
								icon: reactIcon,
								title: 'React',
								summary: 'The main purpose of React is to be **fast**, **scalable**, and **simple**. We use **React Hooks** and **React Redux**.'
							},
							{
								icon: webassemblyIcon,
								title: 'WebAssembly',
								summary: 'To get the **better performance** of the web you can use WebAssembly. We have **Rust** programming experience to help you with that.'
							},
							{
								icon: electronIcon,
								title: 'Electron',
								summary: 'Electron helps you developing **cross-platform applications** by using existing **web technologies**.'
							},
							{
								icon: pwaIcon,
								title: 'PWA',
								summary: 'It enables web apps to load when there is **no network**, sync in the background and provides a **native-like experience** for your users.'
							},
							{
								icon: swiftIcon,
								title: 'SwiftUI',
								summary: 'SwiftUI is an **innovative**, **exceptionally simple** way to build user interfaces across **all Apple platforms** with the power of Swift.'
							},
							{
								icon: flutterIcon,
								title: 'Flutter',
								summary: 'Flutter is a free and **open source** Google **mobile UI** framework that provides a fast and expressive way for developers to build native apps.'
							}
						]
					}
				</Grid>
			</Layout>
			<Layout
				direction='row'
				columns={ [2, 1] }
				alignments={ ['stretch bottom', 'stretch bottom'] }
			>
				<Layout aspectRatio='21:9'>
					<Media modifiers={ ['--image-size-cover'] } src={ colbi } />
				</Layout>
				<Layout
					columns={ [1, 2] }
					alignments={ ['stretch stretch', 'stretch bottom'] }
				>
					<></>
					<Layout aspectRatio='21:9'>
						<Media modifiers={ ['--image-size-cover', '--image-position-left-top'] } src={ meoMusic } />
					</Layout>
				</Layout>
			</Layout>
			<Layout
				direction='row'
				columns={ [5, 4] }
				alignments={ ['stretch bottom', 'stretch bottom'] }
				modifiers={ [
					'--root-width-normal',
					'--content-padding-horizontal-big',
					'--content-padding-bottom-normal'
				] }
			>
				<Layout
					modifiers={ [
						'--content-padding-right-small',
						'--content-padding-top-small',
						'--content-padding-bottom-small'
					] }
				>
					<SectionSummary
						modifiers={ ['--title-decoration-underlined'] }
						title="Our Work"
						summary={ `We've made a lot of **big changes** on our **partners**. Check out our [portfolio](/about-us#portfolio).` }
					/>
				</Layout>
				<Layout aspectRatio='21:9'>
					<Media modifiers={ ['--image-size-cover', '--image-position-left-center'] } src={ tvg } />
				</Layout>
			</Layout>
		</>
	)
}

export default UiUxDevelopment