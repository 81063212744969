import styles from './Dropdown.module.sass'

import React, { useState, useRef, useEffect, Children } from 'react'
import Modal from '../../Modal/Modal'

const Chevron = (
	<svg x="0px" y="0px" viewBox="0 0 256 256" xmlSpace="preserve">
		<polygon points="225.813,48.907 128,146.72 30.187,48.907 0,79.093 128,207.093 256,79.093 " />
	</svg>
)

const Dropdown = (props) => {
	const {
		value,
		options: propOptions,
		primary,
		menu,
		menuLink,
		className,
		children,
		onChange,
		...otherProps
	} = props

	const [open, setOpen] = useState(false)
	const [style, setStyle] = useState({})

	const target = useRef(null)
	const optionsElem = useRef(null)

	const options = propOptions || (Array.isArray(children) ? children : Children.toArray(children))

	useEffect(() => {
		const updatePosition = () => {
			requestAnimationFrame(() => {
				if (open && optionsElem.current) {
					const optionsRect = optionsElem.current.getBoundingClientRect()
					const targetRect = target.current.getBoundingClientRect()
					const w = optionsElem.current.ownerDocument.defaultView
					if (targetRect.left + optionsRect.width > w.innerWidth) {
						setStyle({
							right: w.innerWidth - (targetRect.left + targetRect.width),
							top: targetRect.top + targetRect.height - 1
						})
					} else {
						setStyle({
							left: targetRect.left,
							top: targetRect.top + targetRect.height - 1
						})
					}
				}
			})
		}
		updatePosition()
		window.addEventListener('scroll', updatePosition)
		return () => {
			window.removeEventListener('scroll', updatePosition)
		}
	}, [open, options, target])
	return (
		<>
			<button
				ref={ target }
				onClick={ () => { setOpen(!open) } }
				className={ `${ styles['button'] } ${ primary ? styles['primary'] : '' } ${ menu ? styles['menu'] : '' } ${ menuLink ? styles['menu-link'] : '' } ${ className || '' }` }
				{ ...otherProps }
			>
				<span>{ (value || {}).name }</span>
				{ Chevron }
			</button>
			{ open && (
				<Modal id='modal' onClick={ () => { setOpen(false) } } >
					<span className={ styles['options'] } ref={ optionsElem } style={ style }>
						{ (options || []).map((option) =>
							<div
								key={ option.id }
								className={ `${ styles['option'] } ${ (value || {}).id === option.id ? styles['is-selected'] : '' }` }
								onClick={ () => {
									setOpen(false)
									onChange && onChange({ target: { value: option } })
								} }
							><small>{ option.name }</small></div>
						) }
					</span>
				</Modal>
			) }
		</>
	)
}

export default Dropdown
