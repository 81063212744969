import styles from './Grid.module.sass'

import React from 'react'
import AppearController from '../AppearController/AppearController'

const Grid = (props) => {
	const { data, children, modifiers = [], cards = [], cardsModifiers = [] } = props
	const items = data || children
	const childItems = (items || []).map((item, index) => {
		const Card = cards[index % cards.length]
		const modifiers = cardsModifiers[index % cardsModifiers.length] || []
		return (
			<AppearController
				key={ index }
				className={ styles['cardholder'] }
			>{ Card && <Card { ...item } modifiers={ modifiers }/> }</AppearController>
		)
	})
	return (
		<div className={ `${ styles['root'] } ${ (modifiers || []).map(m => styles[m] || '').join(' ') }` }>
			<div className={ styles['grid-content'] }>
				{ childItems }
			</div>
		</div>
	)
}

export default Grid
