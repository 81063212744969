import React, { createContext, useContext } from 'react'

const GlobalContext = createContext({})
const GlobalProvider = (props) => {
	const { children, ...globalProps } = props
	return (
		<GlobalContext.Provider value={{...globalProps}}>{ children }</GlobalContext.Provider>
	)
}

export default GlobalProvider
export const useGlobalValue = () => useContext(GlobalContext) || {}
