import React from 'react'
import { createPortal } from 'react-dom'
import usePortal from './usePortal'

const Modal = ({ id, onClick, children }) => {
	const target = usePortal(id)
	const portal = (
		<>
			<span style={ { position: 'absolute', left: 0, right: 0, bottom: 0, top: 0 } } onClick={ onClick } />
			{ children }
		</>
	)
	return createPortal(portal, target)
}

export default Modal