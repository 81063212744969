import React from 'react'

const Icon = (props) => {
	const { name, className, style } = props
	const linkHref = `#${ name }`
	return (
		<svg className={ `icon ${ className || '' }` } style={ style }>
			<use href={ linkHref } />
		</svg>
	)
}

export default Icon
