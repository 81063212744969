import styles from './SectionSummary.module.sass'

import React from 'react'
import Markdown from '../Markdown/Markdown'

const SectionSummary = (props) => {
	const { title, summary, smallTitle, modifiers } = props
	const Title = smallTitle ? 'h3' : 'h2'
	const titleClassName = smallTitle ? styles['small'] : ''
	return (
		<div className={ `${ styles['root'] } ${ (modifiers || []).map(m => styles[m] || '').join(' ') }` }>
			<div className={ styles['title-container'] }>
				<Title className={ `${ styles['title'] } ${ titleClassName }` }>{ title }</Title>
			</div>
			<div className={ styles['summary'] }>
				<Markdown>{ summary }</Markdown>
			</div>
		</div>
	)
}

export default SectionSummary