import styles from './Layout.module.sass'

import React, { Children, Fragment } from 'react'
import AppearController from '../AppearController/AppearController'


const UNIT_REGEX = '([0-9]+(.[0-9]+)?)(([a-z]|%)*)'

const renderChild = (child, key, size, alignment) => {
	const match = new RegExp(UNIT_REGEX, 'gm').exec(size) || []
	const sizeValue = match[1]
	const unit = match[3]
	const [hAlignment, vAlignment] = (alignment || '').split(' ')
	const isFragment = child && child.type === Fragment
	return (
		<div
			key={ key }
			className={ `${ styles['cardholder'] } ${ styles[hAlignment] || '' } ${ styles[`vertical-${vAlignment}`] || '' }` }
			style={ {
				flex: isFragment ? undefined : `1 1 ${ sizeValue || 'auto' }${ unit || '%' }`,
				alignItems: vAlignment,
				justifyContent: hAlignment
			} }
		>
			{ child }
		</div>
	)
}

const renderChildren = (children, columns, sizes, alignments) => {
	const childArray = children ? Array.isArray(children) ? children : Children.toArray(children) : []
	if (Array.isArray(columns) && columns.length > 1) {
		const numberOfCols = columns.reduce((acc, colSpan) => acc += colSpan, 0)

		const columnsItems = columns.map((colSpan, index) => {
			const child = childArray[index]
			const size = Math.round((((100 / numberOfCols) * colSpan) * 100)) / 100
			return renderChild(
				child,
				index,
				sizes[index] || (sizes.length ? 'auto' : `${ size.toString() }`),
				alignments[index % alignments.length]
			)
		})
		return columnsItems
	} else if (columns) {
		return childArray.map((child, index) =>
			renderChild(
				child,
				index,
				sizes[index % sizes.length] || 'auto',
				alignments[index % alignments.length]
			)
		)
	}
	return childArray
}

const Layout = (props) => {
	const {
		id,
		appear,
		className,
		children,
		columns,
		sizes = [],
		alignments = [],
		modifiers = [],
		aspectRatio,
		direction,
		size,
		forwardRef
	} = props

	const content = renderChildren(children || [], columns, sizes || [], alignments || [])

	const [wRatio, hRatio] = (aspectRatio || '').split(':')
	const withAspect = wRatio && hRatio ? styles['with-aspect-ratio'] : ''

	const Wrapper = appear ? AppearController : Fragment
	return (
		<Wrapper>
			<div
				id={ id }
				ref={ forwardRef }
				className={ `${ styles['root'] } ${ modifiers.map(m => styles[m] || '').join(' ') } ${ className || '' }` }
				style={ { minHeight: size } }
			>
				<div
					className={ `${ styles['content'] } ${ withAspect } ${ styles[direction] || '' }` }
					style={ {
						paddingTop: wRatio && hRatio ? `${ Math.round((hRatio / wRatio) * 100 * 100) / 100 }%` : undefined
					} }
				>
					{ content }
				</div>
			</div>
		</Wrapper>
	)
}

export default Layout
