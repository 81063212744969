import styles from './CallToAction.module.sass'

import React from 'react'
import { useGlobalValue } from '../../controllers/GlobalContext'
import Markdown from '../Markdown/Markdown'
import Button from '../inputs/Button/Button'

const CallToAction = (props) => {
	const { title, subtitle } = props
	const { actions = {} } = useGlobalValue()
	return (
		<div className={ styles['root'] }>
			<div>
				<h2 className={ styles['title'] }>{ title }</h2>
				<Markdown>{ subtitle }</Markdown>
			</div>
			<Button className={ styles['button'] } primary onClick={ actions.openGetAQuote }>Get a quote</Button>
		</div>
	)
}

export default CallToAction
