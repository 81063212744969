import styles from './Button.module.sass'

import React from 'react'

const Button = (props) => {
	const { className, primary, menu, children, ...otherProps } = props
	return (
		<button className={ `${ styles['button'] } ${ primary ? styles['primary'] : '' } ${ menu ? styles['menu'] : '' } ${ className || '' }` } { ...otherProps }>{ children }</button>
	)
}

export default Button