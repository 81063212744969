import styles from '../App.module.sass'

import React from 'react'

import Layout from '../components/Layout/Layout'
import Media from '../components/Media/Media'
import Animator from '../components/Animator/Animator'
import VerticalThumbsCarousel from '../components/VerticalThumbsCarousel/VerticalThumbsCarousel'
import ScrollIndicator from '../components/ScrollIndicator/ScrollIndicator'
import SectionSummary from '../components/SectionSummary/SectionSummary'
import Grid from '../components/Grid/Grid'
import SimpleCard from '../components/cards/SimpleCard/SimpleCard'
import NewsCard from '../components/cards/NewsCard/NewsCard'

import headerBg from '../assets/header-bg.png'
import uiUxBg from '../assets/ui-ux-background.svg'
import uiUxBgMobile from '../assets/ui-ux-background@mobile.svg'
import uiUxGraphics from '../assets/ui-ux-graphics.svg'
import uiUxGraphicsMobile from '../assets/ui-ux-graphics@mobile.svg'
import apiProgramming from '../assets/api-programming.svg'
import apiProgrammingMobile from '../assets/api-programming@mobile.svg'
import deployProgramming from '../assets/deploy-scale-monitoring.svg'
import deployProgrammingMobile from '../assets/deploy-scale-monitoring@mobile.svg'
import houdiniCSS from '../assets/houdini-css.png'

import beAptLogo from '../assets/beapt-logo.png'
import ppbLogo from '../assets/ppb-logo.svg'
import nmusicLogo from '../assets/nmusic-logo.svg'
import tvgLogo from '../assets/tvg-logo.svg'
import petapilotLogo from '../assets/petapilot-logo.png'
import kkLogo from '../assets/kk-logo.png'
import ymediaLogo from '../assets/ymedia-logo.png'
import cloudwareLogo from '../assets/cloudware-logo.png'


const keyframes = [
	{
		source: 2,
		element: '*',
		end: 0,
		animation: 'display',
		values: {
			from: 'none',
			to: 'inline'
		}
	},
	{
		source: 3,
		element: '*',
		end: 0,
		animation: 'display',
		values: {
			from: 'none',
			to: 'inline'
		}
	},
	{
		source: 3,
		element: 'restricted-and-private-area',
		start: 0.22,
		end: 0.22,
		animation: 'display',
		values: {
			from: 'none',
			to: 'inline'
		}
	},
	{
		source: 3,
		element: 'restricted-and-private-area',
		start: 0.22,
		end: 0.4,
		animation: 'dash'
	},
	{
		source: 3,
		element: 'content',
		start: 0.45,
		end: 0.45,
		animation: 'display',
		values: {
			from: 'none',
			to: 'inline'
		}
	},
	{
		source: 3,
		element: 'content',
		start: 0.45,
		end: 0.50,
		animation: 'opacity',
		values: {
			from: 0,
			to: 1
		}
	},
	{
		source: 3,
		element: 'ui-ux-graphics',
		start: 0.51,
		end: 0.53,
		animation: 'opacity',
		values: {
			from: 1,
			to: 0
		}
	},
	{
		source: 2,
		element: 'imac',
		start: 0.53,
		end: 0.6,
		animation: 'transform',
		values: {
			from: {
				translate: [0, 0],
				scale: [1, 1],
			},
			to: {
				translate: [-1000, 100],
				scale: [1.5, 1.5],
			}
		}
	},
	{
		source: 2,
		element: 'iphone',
		start: 0.53,
		end: 0.58,
		animation: 'transform',
		values: {
			from: {
				translate: [0, 0],
				scale: [1, 1],
			},
			to: {
				translate: [-1000, 250],
				scale: [1.5, 1.5],
			}
		}
	},
	{
		source: 2,
		element: 'iphone',
		start: 0.58,
		end: 0.58,
		animation: 'display',
		values: {
			from: '',
			to: 'none'
		}
	},
	{
		source: 1,
		element: '*',
		start: 0.6,
		end: 0.6,
		animation: 'display',
		values: {
			from: 'none',
			to: 'inline'
		}
	},
	{
		source: 1,
		element: 'base',
		start: 0.6,
		end: 0.6,
		animation: 'display',
		values: {
			from: 'none',
			to: 'inline'
		}
	},
	{
		source: 1,
		element: 'base',
		start: 0.6,
		end: 0.62,
		animation: 'opacity',
		values: {
			from: 0,
			to: 1
		}
	},
	{
		source: 1,
		element: 'components',
		start: 0.65,
		end: 0.65,
		animation: 'display',
		values: {
			from: 'none',
			to: 'inline'
		}
	},
	{
		source: 1,
		element: 'components',
		start: 0.65,
		end: 0.67,
		animation: 'opacity',
		values: {
			from: 0,
			to: 1
		}
	},
	{
		source: 1,
		element: 'components',
		start: 0.73,
		end: 0.75,
		animation: 'opacity',
		values: {
			from: 1,
			to: 0
		}
	},
	{
		source: 1,
		element: 'components',
		start: 0.75,
		end: 0.75,
		animation: 'display',
		values: {
			from: '',
			to: 'none'
		}
	},
	{
		source: 1,
		element: 'base',
		start: 0.78,
		end: 0.85,
		animation: 'transform',
		values: {
			from: {
				translate: [0, 0],
				scale: [1, 1],
			},
			to: {
				translate: [300, 250],
				scale: [2, 2],
			}
		}
	},
	{
		source: 2,
		element: 'imac',
		start: 0.78,
		end: 0.85,
		animation: 'transform',
		values: {
			from: {
				translate: [-1000, 100],
				scale: [1.5, 1.5],
			},
			to: {
				translate: [-1300, 300],
				scale: [2, 2],
			}
		}
	},
	{
		source: 1,
		element: '*',
		start: 0.85,
		end: 0.85,
		animation: 'display',
		values: {
			from: '',
			to: 'none'
		}
	},
	{
		source: 2,
		element: '*',
		start: 0.85,
		end: 0.85,
		animation: 'display',
		values: {
			from: '',
			to: 'none'
		}
	},
	{
		source: 3,
		element: '*',
		start: 0.85,
		end: 0.85,
		animation: 'display',
		values: {
			from: '',
			to: 'none'
		}
	},
	{
		source: 0,
		element: '*',
		start: 0.80,
		end: 0.80,
		animation: 'display',
		values: {
			from: 'none',
			to: 'inline'
		}
	},
	{
		source: 0,
		element: 'rack',
		start: 0.80,
		end: 0.80,
		animation: 'display',
		values: {
			from: 'none',
			to: 'inline'
		}
	},
	{
		source: 0,
		element: 'rack',
		start: 0.80,
		end: 0.88,
		animation: 'opacity',
		values: {
			from: 0,
			to: 1,
		}
	},
	{
		source: 0,
		element: 'rack',
		start: 0.80,
		end: 0.88,
		animation: 'transform',
		values: {
			from: {
				scale: [0.8, 0.8]
			},
			to: {
				scale: [1, 1]
			},
		}
	},
	{
		source: 0,
		element: 'clusters',
		start: 0.94,
		end: 0.94,
		animation: 'display',
		values: {
			from: 'none',
			to: 'inline'
		}
	},
	{
		source: 0,
		element: 'clusters',
		start: 0.94,
		end: 0.96,
		animation: 'opacity',
		values: {
			from: 0,
			to: 1,
		}
	},
]

const Homepage = () => {
	return (
		<>
			<Layout
				modifiers={ [
					'--root-background-diagonal-gradient',
					'--content-display-stacked-behind'
				] }
			>
				<Layout>
					<Layout
						modifiers={ [
							'--root-position-sticky-top'
						] }
						size={ '100vh' }
					>
						<Media modifiers={ ['--image-position-center-bottom'] } src={ headerBg } />
					</Layout>
				</Layout>
				<Layout
					size={ '100vh' }
					direction='column'
					columns={ [2, 1] }
					sizes={ ['80%'] }
					alignments={ ['stretch stretch', 'stretch center'] }
				>
					<Layout
						modifiers={ [
							'--root-width-normal',
							'--content-padding-horizontal-big',
							'--content-margin-vertical-big'
						] }
					>
						<Layout
							size='100%'
							modifiers={ [
								'--content-justify-center'
							] }
						>
							<VerticalThumbsCarousel>
								{
									[
										[
											{ title: 'We can accelerate your product time to market' },
											{ title: 'We keep everyone involved from the start' },
											{ title: 'We can provide extra team members' },
										],
										[
											{ title: 'Fullstack Engineers', summary: 'With over **10 years** of experience, we can help you **design** and **build** a **MVP**. We use state of the art technology to build **resilient** and **scalable** solutions.' },
											{ title: 'Knowledge Transfer', summary: 'We teach your IT teams the **best practices** and we give them a **code walk-through**. We also promote **in-house workshops** so everyone can **get trained**.' },
											{ title: 'Working Anywhere', summary: 'We provide **extra team members** for your IT Projects. We teach **software development best practices** helping your teams to **increase seniority**.' },
										],
									]
								}
							</VerticalThumbsCarousel>
						</Layout>
						<Layout
							size='80px'
							modifiers={ [
								'--root-position-sticky-bottom',
								'--root-width-normal',
								'--content-padding-horizontal-big',
								'--content-justify-center',
								'--content-align-center'
							] }
						>
							<ScrollIndicator />
						</Layout>
					</Layout>
					<Layout
						direction='row-reverse'
						columns={ [1, 1] }
						alignments={ ['stretch stretch', 'stretch center'] }
						modifiers={ [
							'--root-width-normal',
							'--root-background-blur',
							'--content-padding-horizontal-normal',
							'--content-gap-normal'
						] }
					>
						<div>
							<NewsCard data={ {
								img: houdiniCSS,
								title: 'What is Houdini CSS?',
								summary: 'Houdini is a set of low-level APIs that exposes parts of the CSS engine, giving developers the power to extend CSS by hooking into the styling and layout process of a browser’s rendering engine.',
								link: 'https://developer.mozilla.org/en-US/docs/Web/Houdini'
							} } />
						</div>
						<Layout>
							<Layout
								direction='row'
								columns={ [] }
								sizes={ ['50%'] }
							>
								<div>
									<h4 className={ styles['title-label'] }>RustFest</h4>
									<p><small>RustFest Netherlands - Q2 2020</small></p>
								</div>
								<div>
									<h4 className={ styles['title-label'] }>Lead Dev London</h4>
									<p><small>Barbican Centre, 24-25 June 2020</small></p>
								</div>
							</Layout>
						</Layout>
					</Layout>
				</Layout>
			</Layout>
			<Layout
				modifiers={ [
					'--content-display-stacked-behind'
				] }
			>
				<Layout>
					<Layout
						modifiers={ ['--root-position-sticky-top'] }
						size={ '100vh' }
					>
						<Animator
							endOfWindowOffset={ true }
							imagesSet={ {
								mobile: [deployProgrammingMobile, apiProgrammingMobile, uiUxBgMobile, uiUxGraphicsMobile],
								all: [deployProgramming, apiProgramming, uiUxBg, uiUxGraphics]
							} }
							keyframes={ keyframes }
						/>
					</Layout>
				</Layout>
				<Layout
					modifiers={ ['--content-padding-horizontal-big', '--root-width-normal'] }
					size={ '100vh' }
					direction='column'
					columns={ [] }
					sizes={ ['100vh'] }
					alignments={ ['stretch center', 'stretch center'] }
				>
					<Layout
						direction='row'
						columns={ [4, 5] }
					>
						<SectionSummary
							modifiers={ ['--title-decoration-underlined'] }
							title='Full Stack MVP?'
							summary={ `We specialize in **full stack** **web** and **mobile development**, **UI/UX**, **Backend Solutions**, and of course helping you scale your platform.` }
						/>
					</Layout>
					<Layout
						direction='row'
						columns={ [4, 5] }
					>
						<SectionSummary
							modifiers={ ['--title-decoration-underlined'] }
							title='UI/UX Guidelines'
							summary={ `We help you **design** and **build** both your user interface and user experience following the **most recent trends**.\nWe also help you define all **functional requirements**. [See More](/ui-ux-development).` }
						/>
					</Layout>
					<Layout
						direction='row'
						columns={ [4, 5] }
					>
						<SectionSummary
							modifiers={ ['--title-decoration-underlined'] }
							title='Modular API'
							summary={ `We build APIs focused both on **different layers** and **different modules**. This **modular approach** helps us to easily build **micro-services** and **dockerize** them. [See More](/apis-programming).` }
						/>
					</Layout>
					<Layout
						direction='row'
						columns={ [4, 5] }
					>
						<SectionSummary
							modifiers={ ['--title-decoration-underlined'] }
							title='CI/CD'
							summary={ `We help you build your CI/CD pipelines. We have experience with CI/CD tools such as [Gitlab](https://www.gitlab.com), [CircleCI](https://circleci.com/) or [Bitbucket](https://www.bitbucket.com).` }
						/>
					</Layout>
					<Layout
						direction='row'
						columns={ [4, 5] }
					>
						<SectionSummary
							modifiers={ ['--title-decoration-underlined'] }
							title='Scalability'
							summary={ `Being **modular** and **micro-service** oriented will help your platform to scale. We can help build a **scalable infra-structure** ([Terraform](https://www.terraform.io/) and [Kubernetes](https://kubernetes.io/)) and also monitoring it (ELK).` }
						/>
					</Layout>
				</Layout>
			</Layout>
			<Layout
				modifiers={ ['--content-padding-horizontal-big', '--content-padding-bottom-small', '--root-width-normal'] }
			>
				<SectionSummary modifiers={ ['--summary-text-align-center'] } summary='We are **proud** to contribute for the **development of our partners**.' />
			</Layout>
			<Layout
				modifiers={ ['--content-padding-horizontal-big', '--content-padding-bottom-big', '--root-width-normal'] }
			>
				<Grid
					cards={ [SimpleCard] }
					modifiers={ ['--grid-size-tiny'] }
					cardsModifiers={ [['--title-text-align-center']] }
				>
					{
						[
							{
								icon: beAptLogo
							},
							{
								icon: cloudwareLogo
							},
							{
								icon: kkLogo
							},
							{
								icon: nmusicLogo
							},
							{
								icon: ppbLogo
							},
							{
								icon: petapilotLogo
							},
							{
								icon: tvgLogo
							},
							{
								icon: ymediaLogo
							}
						]
					}
				</Grid>
			</Layout>
		</>
	)
}

export default Homepage
