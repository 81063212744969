import React from 'react'

import Layout from '../components/Layout/Layout'
import Media from '../components/Media/Media'
import ScrollIndicator from '../components/ScrollIndicator/ScrollIndicator'
import SectionSummary from '../components/SectionSummary/SectionSummary'
import Grid from '../components/Grid/Grid'
import SimpleCard from '../components/cards/SimpleCard/SimpleCard'

import headerBg from '../assets/apis-programming.svg'
import headerBgMobile from '../assets/apis-programming@mobile.svg'
import functionsIcon from '../assets/functions-icon.svg'
import componentsIcon from '../assets/components-icon.svg'
import apiIcon from '../assets/api-icon.svg'
import graphqlIcon from '../assets/graphql-icon.svg'
import pushIcon from '../assets/push-icon.svg'
import oauthIcon from '../assets/oauth-icon.svg'
import notificationsIcon from '../assets/notifications-icon.svg'
import paymentsIcon from '../assets/payments-icon.svg'
import redisIcon from '../assets/redis-icon.svg'
import postgreIcon from '../assets/postgre-icon.svg'
import mongoIcon from '../assets/mongo-icon.svg'
import dgraphIcon from '../assets/dgraph-icon.svg'
import dockerIcon from '../assets/docker-icon.svg'
import kubernetesIcon from '../assets/kubernetes-icon.svg'
import terraformIcon from '../assets/terraform-icon.svg'
import elkIcon from '../assets/elk-icon.svg'
import backendTechnologies from '../assets/backend-technologies.svg'


const UiUxDevelopment = () => {
	return (
		<>
			<Layout
				modifiers={ [
					'--root-background-diagonal-gradient',
					'--content-display-stacked-behind'
				] }
			>
				<Layout
					size={ '100vh' }
				>
					<Media modifiers={ ['--image-position-center-bottom', '--image-size-cover'] } srcSet={ { 'mobile': headerBgMobile, 'all': headerBg } } />
				</Layout>
				<Layout
					size={ '100vh' }
					direction='column'
					modifiers={ [
						'--content-justify-center'
					] }
				>
					<Layout
						direction='row'
						columns={ [4, 4] }
						modifiers={ [
							'--root-width-normal',
							'--content-padding-horizontal-big',
							'--content-padding-vertical-big',
							'--content-margin-vertical-big'
						] }
					>
						<Layout
							modifiers={ [
								'--content-padding-right-normal'
							] }
						>
							<SectionSummary
								modifiers={ ['--title-decoration-underlined'] }
								title='APIs Programming'
								summary={ 'We help you make the **right decisions** and to build the **right architecture** for your service/platform. **Performance** and **scalability** is our main concern.' }
							/>
							<Layout aspectRatio='120:10' modifiers={ ['--content-margin-top-tiny'] }>
								<Media src={ backendTechnologies } modifiers={ ['--image-position-left-center'] } />
							</Layout>
						</Layout>
					</Layout>
					<Layout
						modifiers={ [
							'--root-position-sticky-bottom',
							'--root-width-normal',
							'--content-padding-horizontal-big',
							'--content-justify-center',
							'--content-align-center'
						] }
					>
						<ScrollIndicator />
					</Layout>
				</Layout>
			</Layout>
			<Layout
				direction='row'
				columns={ [4, 5] }
				modifiers={ [
					'--root-width-normal',
					'--content-padding-vertical-normal',
					'--content-padding-bottom-small',
					'--content-padding-horizontal-big'
				] }
			>
				<SectionSummary
					modifiers={ ['--title-decoration-underlined'] }
					title="Technologies"
					summary={ `We write our code using [Rust](https://www.rust-lang.org/) and [Node.js](https://www.nodejs.org/) to get both **modular** and **high performance** API components. We build pipelines for **CI/CD** that works with providers such as [AWS](https://aws.amazon.com/), [Google Cloud](https://cloud.google.com/) or [Azure](https://azure.microsoft.com/).` }
				/>
			</Layout>
			<Layout
				modifiers={ [
					'--root-width-normal',
					'--content-padding-horizontal-big',
					'--content-padding-bottom-normal'
				] }
			>
				<Grid
					cards={ [SimpleCard] }
					modifiers={ ['--grip-gap-big'] }
					cardsModifiers={ [[]] }
				>
					{
						[
							{
								icon: apiIcon,
								title: 'REST APIs',
								summary: 'We build simple yet powerful REST APIs. **Versioning** and **flexibility** are one of the key points.'
							},
							{
								icon: graphqlIcon,
								title: 'GraphQL APIs',
								summary: 'To get more **data flow control**, we advise our partners to build a [GraphQL](https://graphql.org/) API.'
							},
							{
								icon: pushIcon,
								title: 'Push API',
								summary: 'Getting **real time** data can be tricky when using polling technics. We can help you to develop **push web-services**.'
							},
							{
								icon: notificationsIcon,
								title: 'Push Notifications',
								summary: 'Push notifications will help your user-experience in what concerns to **long async operations**.'
							},
							{
								icon: functionsIcon,
								title: 'Functions',
								summary: 'We make use of cloud functions in order to **reduce costs** and improve **performance** and **scalability**.'
							},
							{
								icon: componentsIcon,
								title: 'Components/Modules',
								summary: 'We build in a **modular** approach (**component library**) to get max **reusability** and simple code **maintenance**.'
							},
							{
								icon: oauthIcon,
								title: 'OAuth',
								summary: 'We use/build **secure authorization** in a simple and standard method for applications or services.'
							},
							{
								icon: paymentsIcon,
								title: 'Payments',
								summary: 'We do several external APIs integrations. For payments we use two of the most used services: [Adyen](https://www.adyen.com/) and [Stripe](https://stripe.com/).'
							},
							{
								icon: redisIcon,
								title: 'Redis',
								summary: '**In-memory data structure** store, used as a database, cache and message broker.'
							},
							{
								icon: postgreIcon,
								title: 'PostgreSQL',
								summary: 'Is a powerful, open source object-relational database system that uses and extends the SQL language.'
							},
							{
								icon: mongoIcon,
								title: 'MongoDB',
								summary: 'Is extremely simple to install and implement. It provides **high performance**, **high availability**, and **automatic scaling**.'
							},
							{
								icon: dgraphIcon,
								title: 'Dgraph',
								summary: '**High-performance** graph database with an emphasis on sound design, thoughtful implementation, resilience, and **cutting edge technologies**.'
							},
							{
								icon: dockerIcon,
								title: 'Docker',
								summary: 'Docker containers allows you to **package** up an **application** with all of the parts it needs, such as libraries and other dependencies.'
							},
							{
								icon: kubernetesIcon,
								title: 'Kubernetes',
								summary: 'An **infrastructure framework** for today with better management through modularity, helping deploying and updating **software at scale**.'
							},
							{
								icon: terraformIcon,
								title: 'Terraform',
								summary: 'It enables you to **define** and **provision** an **infrastructure** using a high-level configuration language.'
							},
							{
								icon: elkIcon,
								title: 'ELK',
								summary: 'ELK is the acronym for three open source projects: **Elasticsearch**, **Logstash**, and **Kibana**. A powerful combination for **monitoring** your services.'
							}
						]
					}
				</Grid>
			</Layout>
		</>
	)
}

export default UiUxDevelopment