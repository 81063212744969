import styles from './Animator.module.sass'

import React, { useEffect, useState, useCallback, useRef, createRef } from 'react'

const Animator = (props) => {
	const {
		images: propsImages,
		imagesSet,
		backgroundImage,
		keyframes: propsKeyframes = [],
		endOfWindowOffset = true,
	} = props
	const [images, setImages] = useState([])
	// const [sourceImages, setSourceImages] = useState([])
	const [currentMedia, setCurrentMedia] = useState()
	const sourceImages = propsImages || imagesSet[currentMedia]
	const container = useRef()
	const svgList = useRef((propsImages || (imagesSet && imagesSet.all) || []).map(() => createRef(null)))

	const keyframes = []
	if (propsKeyframes && Array.isArray(propsKeyframes)) {
		propsKeyframes.forEach((keyframe) => {
			if (keyframe.steps) {
				const stepLength = ((keyframe.end || 1) - (keyframe.start || 0)) / keyframe.steps
				for (let i = 0;i < keyframe.steps;i++) {
					keyframes.push({
						...keyframe,
						start: (i * stepLength),
						end: (i * stepLength) + stepLength
					})
				}
			} else {
				keyframes.push(keyframe)
			}
		})
	}

	const getElements = (source, element, animation) => {
		const rootElement = (svgList.current[source] || {}).current
		if (!rootElement) { return [] }
		const query = element === '*' ? 'svg' : `#source${ source }-${ element }`

		switch (animation) {
			case 'dash': {
				return rootElement.querySelectorAll(`${ query } > *`)
			}
			default: {
				return rootElement.querySelectorAll(query)
			}
		}
	}

	const computeStep = useCallback((keyframe, percentage) => {
		const {
			source = 0,
			element,
			start = 0,
			end = 1,
			animation,
			values: keyframeValues,
			valuesSet,
		} = keyframe
		
		const values = (
			keyframeValues ?
				keyframeValues :
				(valuesSet ? (valuesSet[currentMedia] || { from: 0, to: 0 }) : { from: 0, to: 0 })
		)

		const keyframePercentage = Math.max(0, Math.min(1, (percentage - start) / (end - start)))

		const domElements = getElements(source, element, animation)
		switch (animation) {
			case 'dash': {
				if (domElements && domElements.length) {
					domElements.forEach((svg) => {
						if (keyframePercentage > 0.99) {
							svg.style.fill = ''
						} else {
							svg.style.fill = 'transparent'
						}
						if (svg.getTotalLength && typeof svg.getTotalLength === 'function') {
							try {
								const drawLength = svg.getTotalLength() * keyframePercentage
								svg.style.strokeDasharray = svg.getTotalLength()
								svg.style.strokeDashoffset = svg.getTotalLength() - drawLength
							} catch (_) { }
						}
					})
				}
				break
			}
			case 'display': {
				const { from, to } = values
				domElements.forEach(svg => {
					if (keyframePercentage > 0.99 && percentage > start) {
						svg.setAttribute('display', to)
					} else {
						svg.setAttribute('display', from)
					}
				})
				break
			}
			case 'opacity': {
				domElements.forEach(svg => {
					const {
						from,
						to
					} = values
					const direction = to < from ? -1 : 1
					const toValue = to < from ? 1 - to : to
					const opacity = from + (direction * toValue * keyframePercentage)
					svg.style.opacity = Math.round(opacity * 100) / 100
				})
				break
			}
			case 'transform': {
				const {
					from,
					to
				} = values
				let transform = ''

				if (from.scale && to.scale) {
					transform += ` ${ `scale(${ to.scale.map((v, i) =>
						(from.scale[i] || 1) + ((v - (from.scale[i] || 1)) * keyframePercentage)
					).join(' ') })` }`
				}
				if (from.rotate && to.rotate) {
					const rotateValues = to.rotate.map((v, i) => {
						if (i === 0) {
							if (v < from.rotate[i]) {
								return from.rotate[i] + (-1 * (Math.abs(v) - Math.abs(from.rotate[i])) * keyframePercentage)
							}
							return from.rotate[i] + ((v - from.rotate[i]) * keyframePercentage)
						}
						return v
					})
					transform += ` ${ `rotate(${ rotateValues.join(' ') })` }`
				}
				if (from.translate && to.translate) {
					const translateValues = to.translate.map((v, i) => {
						if (v < from.translate[i]) {
							return from.translate[i] + (-1 * (Math.abs(v) - Math.abs(from.translate[i])) * keyframePercentage)
						}
						return from.translate[i] + ((v - from.translate[i]) * keyframePercentage)
					})
					transform += ` ${ `translate(${ translateValues.join(', ') })` }`
				}
				domElements.forEach(svg => {
					svg.setAttribute('transform', transform)
				})
				break
			}
			default: break
		}
	}, [currentMedia])

	const computeSteps = useCallback((keyframes, percentage) => {

		keyframes.forEach((keyframe, index) => {
			const values = (
				keyframe.values ?
					keyframe.values :
					(keyframe.valuesSet ? (keyframe.valuesSet[currentMedia] || { from: 0, to: 0 }) : { from: 0, to: 0 })
			)
			if ((keyframe.start || 0) <= percentage) {
				computeStep(keyframe, percentage)
			} else {
				const conflictsWithPrevious = keyframes.slice(0, index).find(({ source, element, animation }) => {
					return (
						keyframe.source === source &&
						keyframe.element === element &&
						keyframe.animation === animation
					)
				})
				if (conflictsWithPrevious) {
					return
				}
				switch (keyframe.animation) {
					case 'display': {
						getElements(keyframe.source, keyframe.element, keyframe.animation)
							.forEach((svg) => {
								svg.setAttribute('display', values.from)
							})
						break
					}
					case 'opacity': {
						getElements(keyframe.source, keyframe.element, keyframe.animation)
							.forEach((svg) => {
								svg.style.opacity = values.from
							})
						break
					}
					case 'transform': {
						const { from } = values
						let transform = ''
						if (from.scale) {
							transform += ` scale(${ from.scale.join(' ') })`
						}
						if (from.translate) {
							transform += ` translate(${ from.translate.join(', ') })`
						}
						getElements(keyframe.source, keyframe.element, keyframe.animation)
							.forEach((svg) => {
								svg.setAttribute('transform', transform)
							})
						break
					}
					default: break
				}
			}
		})

	}, [computeStep, currentMedia])

	const computeAnimationFrame = useCallback(() => {
		const parentContainer = container.current.parentNode.parentNode.parentNode
		const scrollTop = (document.documentElement.scrollTop + document.body.scrollTop)
		const viewportHeight = endOfWindowOffset ? window.innerHeight : 0
		const elementOffsetTop = parentContainer.getBoundingClientRect().top + document.documentElement.scrollTop
		const elementHeight = parentContainer.offsetHeight


		const distance = Math.max(0, (scrollTop + viewportHeight) - elementOffsetTop)
		const percentage = (distance / ((viewportHeight + elementHeight - elementOffsetTop) / 100)) / 100
		computeSteps(keyframes || [], percentage)
	}, [keyframes, computeSteps, endOfWindowOffset])

	useEffect(() => {
		const imagesSources = sourceImages || []
		const promises = Promise.all(imagesSources.map((img) =>
			fetch(img)
				.then(r => r.text())
			// .then(r => { setImage(r) })
		))
			.then((images) => {
				if (images && images.length) {
					setImages(images.map((imgData, index) => {
						let nextData = imgData
							.replace(/id="(.*?)"/g, (matched, value) => {
								return matched.replace(value, `source${ index }-${ value }`)
							})
							.replace(/url\(#(.*?)\)/g, (matched, value) => {
								return matched.replace(value, `source${ index }-${ value }`)
							})
							.replace(/xlink:href="#(.*?)"/g, (matched, value) => {
								return matched.replace(value, `source${ index }-${ value }`)
							})

						return nextData
					}))
				}
			})

		return () => {
			Promise.resolve(promises)
		}

	}, [sourceImages])

	useEffect(() => {
		computeAnimationFrame()
	}, [images.length, computeAnimationFrame])

	useEffect(() => {
		if (typeof window !== 'undefined' && images.length) {

			computeAnimationFrame()

			const handleScroll = () => {
				setTimeout(() => {
					computeAnimationFrame()
				})
			}
			window.addEventListener('scroll', handleScroll)
			return () => {
				window.removeEventListener('scroll', handleScroll)
			}
		}
	}, [images.length, computeAnimationFrame])

	useEffect(() => {
		const handleResize = () => {
			const w = window.innerWidth
			if (w <= 800 && imagesSet.mobile) {
				// setSourceImages(imagesSet.mobile)
				setCurrentMedia('mobile')
			} else {
				// setSourceImages(imagesSet.all)
				setCurrentMedia('all')
			}
		}
		handleResize()
		window.addEventListener('resize', handleResize)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
		// if (imagesSet && typeof window !== 'undefined') {

		// } else if (propsImages) {
		// 	setSourceImages(propsImages)
		// }
	}, [propsImages, imagesSet])

	return (
		<div className={ styles['root'] } ref={ container }>
			{ backgroundImage && <div className={ styles['background-image'] } style={ { backgroundImage: `url(${ backgroundImage })` } } /> }
			{ images.length ? images.map((image, index) => (
				<div
					key={ index }
					className={ styles['svg-container'] }
					ref={ svgList.current[index] }
					dangerouslySetInnerHTML={ { __html: image } }
				/>
			)) : null }
		</div>
	)
}

export default Animator
