import styles from './NewsCard.module.sass'

import React from 'react'
import Media from "../../Media/Media"

const NewsCard = (props) => {
	const { data } = props
	const { img, title, summary, link } = data || {}
	return (
		<div className={ styles['root'] }>
			<div>
				<div className={ styles['image-container'] }>
					<Media modifiers={['--image-size-cover']} src={ img } />
				</div>
			</div>
			<div className={ styles['details'] }>
				<h4 className={ styles['title'] }>{ title }</h4>
				<div className={ styles['summary'] }>
					<span className={ styles['text'] }>{ summary }</span>
					{ link ? <a className={ styles['link'] } href={ link } target="_blank" rel="noopener noreferrer">Read More</a> : null }
				</div>
			</div>
		</div>
	)
}

export default NewsCard