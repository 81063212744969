import styles from './LMenu.module.sass'

import React, { useState, useEffect, useRef } from 'react'
import { NavLink, useHistory } from 'react-router-dom'

import { useGlobalValue } from '../../controllers/GlobalContext'
import Button from '../inputs/Button/Button'
import Dropdown from '../inputs/Dropdown/Dropdown'

const LMenu = (props) => {
	const { logo, navigation = [] } = props
	const { actions = {}, currentRoute } = useGlobalValue()
	// For aside menu with social widgets
	// const [open, setOpen] = useState(false)
	// const [close, setClose] = useState(false)
	const [menuOverflow, setMenuOverflow] = useState(false)
	const navLinks = useRef(null)
	const history = useHistory()

	useEffect(() => {
		const handleResize = () => {
			setMenuOverflow(navLinks.current.offsetWidth < navLinks.current.scrollWidth)
		}
		if (typeof window !== 'undefined') {
			window.addEventListener('resize', handleResize)
			handleResize()
			return () => {
				window.removeEventListener('resize', handleResize)
			}
		}
	}, [navLinks])
	return (
		<>
			{/* <aside className={ `${ styles['aside'] } ${ open ? styles['is-open'] : '' }  ${ close ? styles['is-close'] : '' }` }>
				<button
					className={ styles['menu-button'] }
					onClick={ () => { setOpen(!open); setClose(open) } }
				>
					<span className={ styles['burger-icon'] } />
				</button>
				<div className={ styles['menu-background'] }>
					{ open && (
						<div className={ styles['menu-content'] }>
							<h2>Menu</h2>
							<div>
								{ (navigation || []).map(({ to, name, exact }, index) => (
									<NavLink
										key={ index }
										to={ to }
										exact={ exact }
										className={ styles['aside-menu-link'] }
										activeClassName={ styles['is-selected'] }
									>
										{ name }
									</NavLink>
								)) }
							</div>
						</div>
					) }
				</div>
			</aside> */}
			<nav className={ styles['menu'] }>
				<NavLink className={ styles['logo'] } to="/">
					<img src={ logo } alt='home' />
				</NavLink>
				<span className={ styles['spacer'] } />
				<span className={ `${ styles['site-links'] } ${ menuOverflow ? styles['is-overflowing'] : styles['is-visible'] }` } ref={ navLinks }>
					{ (navigation || []).map(({ to, name, exact }, index) => (
						<NavLink
							key={ index }
							to={ to }
							exact={ exact }
							className={ styles['link'] }
							activeClassName={ styles['is-selected'] }
						>
							{ name }
						</NavLink>
					)) }
				</span>
				<span className={ styles['right-side'] }>
					{ menuOverflow && (
						<Dropdown
							className={ styles['dropdown'] }
							menuLink
							value={
								{
									...currentRoute,
									id: currentRoute.to
								}
							}
							onChange={ (e) => {
								const { id: pathname } = e.target.value || {}
								history.push(pathname)
							} }
						>
							{ (navigation || []).map(({ to, name, exact }, index) => (
								{
									id: to,
									name,
								}
							)) }
						</Dropdown>
					) }
					<Button className={ styles['action-button'] } menu onClick={ actions.openGetAQuote }>Get a Quote</Button>
				</span>
			</nav>
		</>
	)
}

export default LMenu